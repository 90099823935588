<!--
 * @Description: 路内停车管理系统 设备管理 地锁设备 floorLock
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="地锁编号">
              <el-input v-model="searchForm.deviceId"
                        clearable
                        placeholder="请输入地锁编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="地锁名称">
              <el-input v-model="searchForm.deviceName"
                        clearable
                        placeholder="请输入地锁名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="地锁地址">
              <el-input v-model="searchForm.address"
                        clearable
                        placeholder="请输入地锁地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="厂商">
              <el-input v-model="searchForm.vendor"
                        clearable
                        placeholder="请输入厂商"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="设备状态">
              <el-select v-model="searchForm.status"
                         placeholder="全部"
                         clearable>
                <el-option v-for="item in statusList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场名称">
              <el-select v-model="searchForm.parkId"
                         placeholder="全部"
                         clearable>
                <el-option v-for="item in parkNameList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="queryButton">查 询</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="resetForm">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>地锁设备</span>
        <span class="tableTitleButton topButton">
          <el-button type="success"
                     @click="handleClickAdd">新 增</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="importDialog = true">导 入</el-button>
          <el-button type="info"
                     style="margin-left:17px">
            <a :href="exportFile"
               download=""
               style="color:white">导 出</a>
          </el-button>
          <el-button type="danger"
                     style="margin-left:17px"
                     @click="massDeletion">批量删除</el-button>
        </span>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :config="tableConfig"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  :tableFun="tableFun"
                  @getList="getList">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="电量">
              <template slot-scope="scope">
                <span v-if="scope.row.battery>=0&&scope.row.battery<=20"
                      style="color:red">{{scope.row.battery}}</span>
                <span v-if="scope.row.battery>20&&scope.row.battery<=60"
                      style="color:yellow">{{scope.row.battery}}</span>
                <span v-if="scope.row.battery>60&&scope.row.battery<=100"
                      style="color:green">{{scope.row.battery}}</span>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="5">
                    <el-button @click="handleClickUpLock(scope.row)"
                               type="text"
                               size="medium">抬锁</el-button>
                  </el-col>
                  <el-col :span="5">
                    <el-button @click="handleClickDownLock(scope.row)"
                               type="text"
                               size="medium">降锁</el-button>
                  </el-col>
                  <el-col :span="5">
                    <el-button @click="handleClickDetail(scope.row)"
                               type="text"
                               size="medium">详情</el-button>
                  </el-col>
                  <el-col :span="5">
                    <el-button type="text"
                               size="medium"
                               @click="handleClickEdit(scope.row)">修改</el-button>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="text"
                               size="medium"
                               @click="handleClickDelete(scope.row)">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增 -->
    <el-dialog title="新增地锁"
               :visible.sync="addDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="addFormList"
               :rules="rules"
               :inline="true"
               ref="addFormList"
               label-width="150px"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备编号"
                          prop="deviceId">
              <el-input v-model="addFormList.deviceId"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称"
                          prop="deviceName">
              <el-input v-model="addFormList.deviceName"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属厂商"
                          prop="vendor">
              <el-input v-model="addFormList.vendor"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="停车场名称"
                          prop="parkId">
              <el-select clearable
                         v-model="addFormList.parkId"
                         placeholder="请选择"
                         class="dt-form-width">
                <el-option v-for="(item, index) in parkNameList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input v-model="addFormList.contact"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="addFormList.contactPhoneNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="经度坐标"
                          prop="longitude">
              <el-input v-model="addFormList.longitude"
                        class="dt-form-width"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度坐标"
                          prop="latitude">
              <el-input v-model="addFormList.latitude"
                        class="dt-form-width"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="addFormList.remark"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickAddConfirm('addFormList')">确 定</el-button>
          <el-button type="primary"
                     class="popBtn"
                     @click="addDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="修改地锁"
               :visible.sync="editDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="editFormList"
               :rules="rules"
               :inline="true"
               ref="editFormList"
               label-width="150px"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备编号"
                          prop="deviceId">
              <el-input v-model="editFormList.deviceId"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称"
                          prop="deviceName">
              <el-input v-model="editFormList.deviceName"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属厂商"
                          prop="vendor">
              <el-input v-model="editFormList.vendor"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="停车场名称"
                          prop="parkId">
              <el-select clearable
                         v-model="editFormList.parkId"
                         placeholder="请选择"
                         class="dt-form-width">
                <el-option v-for="(item, index) in parkNameList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input v-model="editFormList.contact"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="editFormList.contactPhoneNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="经度坐标"
                          prop="longitude">
              <el-input v-model="editFormList.longitude"
                        class="dt-form-width"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度坐标"
                          prop="latitude">
              <el-input v-model="editFormList.latitude"
                        class="dt-form-width"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>

          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="editFormList.remark"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickEditConfirm('editFormList')">确 定</el-button>
          <el-button type="primary"
                     class="popBtn"
                     @click="editDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog title="地锁详情"
               :visible.sync="detailDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="detailFormList"
               :rules="rules"
               :inline="true"
               ref="detailFormList"
               label-width="150px"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备编号"
                          prop="deviceId">
              <el-input v-model="detailFormList.deviceId"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称"
                          prop="deviceName">
              <el-input v-model="detailFormList.deviceName"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="停车场名称"
                          prop="parkId">
              <el-select clearable
                         v-model="detailFormList.parkId"
                         placeholder="请选择"
                         class="dt-form-width"
                         disabled>
                <el-option v-for="(item, index) in parkNameList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="所属厂商"
                          prop="vendor">
              <el-input v-model="detailFormList.vendor"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input v-model="detailFormList.contact"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="detailFormList.contactPhoneNumber"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="经度坐标"
                          prop="longitude">
              <el-input v-model="detailFormList.longitude"
                        class="dt-form-width"
                        readonly
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度坐标"
                          prop="latitude">
              <el-input v-model="detailFormList.latitude"
                        class="dt-form-width"
                        readonly
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备地址"
                          prop="address">
              <el-input v-model="detailFormList.address"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="detailFormList.remark"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="detailDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog title="导入数据"
               :visible.sync="importDialog"
               :close-on-click-modal="false"
               append-to-body>
      <el-upload ref="upload"
                 :auto-upload="false"
                 :file-list="fileList"
                 :http-request="myUpload"
                 :limit="1"
                 :on-change="addFile"
                 :on-exceed="handleExceed"
                 :show-file-list="true"
                 accept=".xls, .xlsx"
                 action=""
                 style="text-align: center;">
        <el-row style="display:flex">
          <el-button size="small"
                     style="margin-left: 15px"
                     type="primary">
            <a :href="templateDl"
               class="download"
               download=""
               style="color: #ffffff;text-decoration:none">模板下载</a>
          </el-button>
          <el-button size="small"
                     type="primary">
            选择文件
          </el-button>
        </el-row>
        <div slot="tip"
             class="el-upload__tip"
             style="font-size:10px;color:#ff0000;margin-top:30px;">
          请先下载模板！
        </div>
      </el-upload>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="confimImportBatch">导 入</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  computed: {
    // 导出
    exportFile: function () {
      return this.$downloadBaseUrl + "groundLock/download";
    }
  },
  data () {
    //这里存放数据
    return {
      tableFun: { 'selection-change': this.handleChange, }, //多选方法
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'deviceId',
          label: '地锁编号',
        },
        {
          prop: 'deviceName',
          label: '地锁名称',
        },
        {
          prop: 'address',
          label: '地锁地址',
        },
        {
          prop: 'vendor',
          label: '厂商',
        },
        {
          prop: 'parkName',
          label: '所属停车场(路段)',
        },
        {
          prop: 'statusDesc',
          label: '设备状态',
        },
      ],
      tableList: {
        list: [],
      },
      searchForm: {},
      pageNum: 1,
      pageSize: 15, //初始化分页

      addFormList: {},// 新增表单
      addDialogVisible: false, // 新增弹窗

      detailFormList: {},// 详情表单
      detailDialogVisible: false, // 详情弹窗

      editFormList: {},// 详情表单
      editDialogVisible: false, //修改弹窗

      massDeleteList: [], // 批量删除绑定

      importDialog: false, // 导入弹窗
      fileList: [],
      templateDl: "",

      rules: {
        parkId: [{ required: true, message: '请选择停车场', trigger: 'change' }],
        deviceId: [{ required: true, message: '请输入设备编号', trigger: 'blur' }],
        deviceName: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        address: [{ required: true, message: '请输入设备地址', trigger: 'blur' }],
        vendor: [{ required: true, message: '请输入设备厂商', trigger: 'blur' }],
        contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contactPhoneNumber: [{ required: true, message: '请输入联系人电话', trigger: 'blur' }],
        longitude: [{ required: true, message: '请输入经度坐标', trigger: 'blur' }],
        latitude: [{ required: true, message: '请输入纬度坐标', trigger: 'blur' }],
      },

      parkNameList: [],//停车场下拉框
      statusList: [],// 设备状态
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.templateDl = this.$downloadBaseUrl + "groundLock" + "/downloadTemplate";
    this.queryTableList()
    this.queryParkList()
    this.queryStatusList()
  },
  //方法集合
  methods: {
    // 上传文件
    addFile (file) {
      if (!(file.name.endsWith("xls") || file.name.endsWith("xlsx"))) {
        this.fileList = [];
        this.$message.warning(`文件格式有误,请选择正确的Excel文件`);
      }
    },
    // 限制文件
    handleExceed () {
      this.$message.warning(`对不起,一次仅限上传一个文件！`);
    },
    // 上传文件  
    myUpload (content) {
      let _self = this;
      var FileController = "";
      FileController = this.$downloadBaseUrl + "groundLock/upload";
      var form = new FormData();
      form.append("file", content.file);
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = onloadFun;
      xhr.open("POST", FileController, true);
      xhr.send(form);
      function onloadFun () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var resText = JSON.parse(xhr.responseText);
          if (resText.resultCode === "2000") {
            _self.fileList = [];
            _self.$message({ message: "导入成功", type: "success" });
            _self.importDialog = false;
            _self.queryTableList();
          } else {
            _self.$message.error({ message: "对不起！文件上传失败", type: "error" });
          }
        }
      }
    },
    // 确认导入按钮
    confimImportBatch () {
      this.$refs.upload.submit();
      this.importDialog = false;
    },
    // 默认渲染
    queryTableList () {
      this.searchForm["pageNum"] = this.pageNum
      this.searchForm["pageSize"] = this.pageSize
      this.$floorLock.queryGroundLockList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询
    queryButton () {
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.searchForm = {}
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.queryTableList()
    },
    // 抬锁
    handleClickUpLock (row) {
      let info = {
        deviceId: row.deviceId
      }
      this.$confirm('是否确定抬锁?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$confirm('请再次确认是否抬锁?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$floorLock.downLock(info).then(() => {
            this.$message({ message: '抬锁成功', type: 'success' })
          })
        }).catch(() => {
          this.$message({ message: '抬锁已取消', type: 'info' })
        })
      }).catch(() => {
        this.$message({ message: '抬锁已取消', type: 'info' })
      })
    },
    // 降锁
    handleClickDownLock (row) {
      let info = {
        deviceId: row.deviceId
      }
      this.$confirm('是否确定降锁?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$confirm('请再次确认是否降锁?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$floorLock.upLock(info).then(() => {
            this.$message({ message: '降锁成功', type: 'success' })
          })
        }).catch(() => {
          this.$message({ message: '降锁已取消', type: 'info' })
        })
      }).catch(() => {
        this.$message({ message: '降锁已取消', type: 'info' })
      })
    },
    // 新增弹窗
    handleClickAdd () {
      this.addDialogVisible = true
      this.addFormList = {}
    },
    // 新增确认提交
    handleClickAddConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$floorLock.addGroundLock(this.addFormList).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '新增成功', type: 'success' });
              this.addDialogVisible = false
              this.queryTableList()
            }
          })
        }
      })
    },
    //查看详情
    handleClickDetail (row) {
      this.detailFormList = row
      this.detailDialogVisible = true
    },
    //修改
    handleClickEdit (row) {
      this.editFormList = {}
      this.editFormList = row
      this.editDialogVisible = true
    },
    // 修改
    handleClickEditConfirm () {
      this.$floorLock.updateGroundLock(this.editFormList).then((response) => {
        if (response.resultCode == 2000) {
          this.$message({ message: '修改成功', type: 'success' });
          this.editDialogVisible = false
          this.queryTableList()
        }
      })
    },
    // 删除请求
    handleClickDelete (row) {
      this.$confirm('是否删除该设备?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$floorLock.deleteGroundLock([{ deviceId: row.deviceId }]).then(res => {
          if (res.resultCode == 2000) {
            this.$message({ message: '删除成功', type: 'success' });
            this.queryTableList()
          }
        })
      });
    },
    //表格多选按钮
    handleChange (val) {
      let arr = []
      val.forEach((item) => {
        arr.push({ deviceId: item.deviceId })
      })
      this.massDeleteList = arr
    },
    //批量删除
    massDeletion () {
      if (this.massDeleteList.length === 0) {
        this.$message({ showClose: true, message: '至少勾选一条记录', type: 'error' });
      } else {
        this.$confirm('确定批量删除地锁?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let info = this.massDeleteList
          this.$floorLock.deleteGroundLock(info).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '删除成功', type: 'success' });
              this.queryTableList()
            }
          })
        })
      }
    },
    //获取停车场名称
    queryParkList () {
      let info = {
        columnName: ['park_id', 'park_name'],
        tableName: 'tb_park',
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
      })
    },
    // 设备状态
    queryStatusList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'BE5701825A9D444F959E53CC17F664A8G',
          },
        ],
      }
      this.$queryDict.queryDict(info).then(res => {
        this.statusList = res.resultEntity
      })
    },
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 185px;
.mainbody {
  height: 100%;
  .topSelectTwoLine {
    @include background('blockBg');
    width: 100%;
    height: $topSelectHeight;
    padding-top: 24.3px;
    padding-left: 21px;
    flex-direction: column;
    .topFirstLine {
      display: flex;
      flex-direction: row;
    }
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .topButton {
        display: flex;
        padding-left: 30px;
      }
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
  .popBtn {
    margin-left: 17px;
    height: 36px;
  }
}
.dt-form-width {
  width: 230px;
}
.title {
  display: inline-block;
  font-size: 16px;
  color: #70aee8;
  line-height: 18px;
}

.lineOne {
  margin-top: 25px;
}
</style>
